@import './colors';
@import './variables';
@import url('https://fonts.googleapis.com/css?family=Graduate&display=swap');

html,
body {
  font-family: $body-font-family !important;
  font-weight: lighter;
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $headers-font-family !important;
}

#id {
  font-family: $body-font-family !important;
}
