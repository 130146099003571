@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css?family=Graduate&display=swap);

.section-wrapper {
  background-image: url(/static/media/ping_pong.6bc1fa42.svg);
  background-repeat: no-repeat; }
  .section-wrapper .section__title {
    color: #4dd0e1 !important;
    text-align: center;
    padding: 28px 14px; }
  .section-wrapper .section--black {
    border-radius: 0 !important;
    margin: 0px !important;
    background: black;
    background: -webkit-linear-gradient(133deg, black 0%, black 50%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(317deg, black 0%, black 50%, rgba(0, 0, 0, 0.7) 100%); }

.cards-group .card {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 6, 7, 0.9)), to(rgba(2, 45, 56, 0.733)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 6, 7, 0.9), rgba(2, 45, 56, 0.733));
  background-image: linear-gradient(to top, rgba(0, 6, 7, 0.9), rgba(2, 45, 56, 0.733));
  color: white;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }
  .cards-group .card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer; }
  .cards-group .card-1 {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    z-index: 10; }
    .cards-group .card-1:hover {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }

.cards-group__image-wrapper {
  margin-bottom: 14px;
  display: flex;
  justify-content: center; }
  .cards-group__image-wrapper .card-img-top {
    width: 148px !important; }

.cards-group .card-body {
  padding: 0px !important; }
  .cards-group .card-body .card-title {
    padding: 3px 7px;
    background: rgba(128, 128, 128, 0.123);
    -webkit-clip-path: polygon(0% 0, 100% 0%, 93% 100%, 0 100%);
            clip-path: polygon(0% 0, 100% 0%, 93% 100%, 0 100%);
    margin-right: 14px;
    color: #d3d3d38a; }

html,
body {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: lighter;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Graduate", cursive !important; }

#id {
  font-family: "Ubuntu", sans-serif !important; }

